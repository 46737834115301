#box{
	margin: 10px;
}
#c_box{
	width: 100%;
	background-color: rgba(0, 0, 0, 0);
	
	overflow: auto;
    white-space: nowrap;
}

#c_box #card{
	display: inline-block;
}

#c_box #hc_box{
	display: inline-block;
}

#c_box #tcard{
	display: inline-block;
	margin: 15px;
}