.card {
    width: 240px;
    height: 344px;
    transition: all 0.2s;
    position: relative;
    cursor: pointer;
    border-radius: 20px;
  }
  
  .card-inner {
    width: inherit;
    height: inherit;
    background: rgba(255,255,255,.05);
    box-shadow: 0 0 10px rgba(0,0,0,0.25);
    backdrop-filter: blur(0px);
    border-radius: 8px;
  }
  
  
  .circle {
    width: 50px;
    height: 50px;
    background: radial-gradient(rgb(197, 0, 246),#7519de);
    
    position: absolute;
    
  }

  .card-inner #tc_img{
    width: 100%;
    overflow: hidden;
    height: 130px;
    padding: 10px;
    
  }

  .card-inner #tc_img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  }

  .card-inner #tc_details{
    margin: 10px 10px 0px 10px;
  }
  
  .circle:nth-child(1) {
    top: -8px;
    left: -8px;
    z-index: -2;
    border-radius: 10px;
  }
  
  .circle:nth-child(2) {
    bottom: -8px;
    right: -8px;
    z-index: -3;
    animation-name: move-down1;
    border-radius: 10px;
  }
  
  @keyframes move-up6 {
    to {
      transform: translateY(-10px);
    }
  }
  
  @keyframes move-down1 {
    to {
      transform: translateY(10px);
    }
  }