*{
    color: rgb(0, 0, 0);
}
::-webkit-scrollbar {
    display: none;
}
#c_body #price{
    margin: 10px 10px 10px 30px;
    font-size: 16px;
}
#c_body #title{
    margin-left: 10px;
    font-size: 20px;
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
#c_body #area{
    margin-left: 10px;
    font-size: 12px;
    font-weight: 400;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
#card{
    width: 320px;
    height: 350px;
    background-color: rgba(127, 255, 212, 0);
    margin-left: 10px;
    overflow: hidden;
    cursor: pointer;
    vertical-align: top;
}

#c_image{
    width: 100%;
    height: 200px;
    overflow: hidden;
    position: relative;
    border-radius: 30px;
}

#c_image img{
    width: 100%;
    object-fit: cover;
}

#c_body{
    width: 96%;
    height: 150px;
    background-color: rgb(255, 255, 255);
    position: absolute;
    top: 150px;
    left:2%;
    border-top-left-radius: 50px;
    border-bottom-right-radius: 50px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}
#c_circle{
    width:100px ;
    height: 100px;
    background-color: #7519de;
    position: absolute;
    border-radius: 50%;
    border:solid 10px rgb(255, 255, 255);
    left: 60%;
    top: 30%;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}
#c_body #keys{
    margin: 10px 0px 0px 10px;
    text-overflow: ellipsis;
}
#c_body #keys b{
    background-color: #7519de;
    padding: 5px;
    border-radius: 10px;
    color: white;
    margin: 5px 5px 0px 0px;
    text-overflow: ellipsis;
}

#c_circle i{
    width: 50px;
    height: 50px;
    position: relative;
    top:2%;
    font-size: 40px;
    text-align: center;
    left: 20px;
    color: white;
}