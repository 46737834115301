#hc_box{
    width: 320px;
    height: 220px;
    background-color: #7519de;
    margin: 10px;
    overflow: hidden;
    cursor: pointer;
    vertical-align: top;
    border-top-right-radius: 50px;
    border-bottom-left-radius: 50px;
    border-radius: 20px;
}

#hc_box #hc_body{
    position: relative;
    width: 100%;
    height: 220px;
    border-radius: 50px;
    background-color: rgb(255, 255, 255);
    padding: 5px;
}

#hc_box #hc_img{
    position: absolute;
    width: 100%;
    height: 100px;
    overflow: hidden;
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    border-top-right-radius: 50px;
    border-top-left-radius: 50px;
    
}

#hc_box #hc_img img{
    width: 100%;
    height: 100px;
   
}

#hc_box #hc_body #hc_body_details{
    margin-left: 10px;
}

