.total_container{
   width: 100%;
   margin-top: 70px;
   display: flex;
   flex-wrap: wrap;
  
}

.banner_container{
    width: 70%;
    height: 100%;
    margin: 10px;
    align-items: center;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
   
}
#category_container a{
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.773);
    padding: 10px;
    border-radius: 10px;
    width: 100%;
    height: 90%;
    margin: 10px;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
   align-items: center;
   text-align: center;
}


#category_container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    
    background-color: wheat;
    align-items: center;
    height: 60px;
  
}

#category_container a:hover{
    background-image: linear-gradient(to right, #e8e7eb , #ffffff);
    transition: color 1s;
    color: aliceblue;
}




#category_container::-webkit-scrollbar { 
    display: none; 
}

.ads_container{
    width: 26%;
    margin: 10px;
    height: 360px;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    align-items: center;
}

.ads_container img{
    width: 100%;
    height: 100%;
}

.carousel-item{
    width: 100%;
    height: 300px;
    background-color: blueviolet;
}



.banner_container img{
    width: 100%;
    height: 100%;
}



@media (max-width: 768px) {
    .banner_container{
        width: 100%;
        
        margin: 10px;
       
        justify-content: center;
    }

    .ads_container{
        width: 100%;
        margin: 10px;
        display: flex;
        justify-content: center;
    }

}


